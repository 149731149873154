* {
	box-sizing: border-box;
}

body {
	background-color: black;
}

.landingTitle {
	font-size: 50px;
	font-weight: bold;
	text-align: center;
	position: relative;
}

.landingTitle::after {
	content: "Discover a healthier you!";
	position: absolute;
	top: 0;
	left: 0;
	background: linear-gradient(to right, rgb(213, 83, 104), rgb(192, 52, 192));
	background-clip: text;
	color: transparent;
	overflow: hidden;
	white-space: nowrap;
	animation: moveText 4s infinite;
}

@keyframes moveText {
	0% {
		width: 20%;
	}
	50% {
		width: 100%;
	}
	100% {
		width: 20%;
	}
}

.landingTitle:hover {
	transform: scale(1.01);
}

.container {
	display: flex;
	flex-direction: row;
	justify-content: space-around; /* evenly distributed , applying equal space around */
	width: 100%; /* This is full width. Adjust to what you need. */
	margin-top: 20px;
}

.container_img {
	width: 700px; /* whatever width you want */
	height: 600px; /* whatever height you want */
	overflow: hidden; /* This ensures the image does not spill out of the container */
	flex: none; /* This prevents shrinking and growing. */
	padding-right: 100px;
	position: relative;
	animation: levitate 2.5s infinite;
}

@keyframes levitate {
	0% {
		transform: translateY(0);
	}
	50% {
		transform: translateY(10px); /* Adjust the distance and direction as desired */
	}
	100% {
		transform: translateY(0);
	}
}

.container_img img {
	width: 100%;
	height: 100%;
	object-fit: contain;
	border-radius: 70%;
}

.container_img img:hover {
	transform: scale(0.99);
}

.container_text {
	flex: none; /* This prevents shrinking and growing. */
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.landingTextBlock {
	font-size: medium;
	font-weight: normal;
	color: white;
	text-align: center;
	width: 600px;
	height: auto;
	padding-top: 10px;
	padding-bottom: 25px;
}

.login-button {
	color: white;
	background: linear-gradient(to right, rgb(213, 83, 104), rgb(192, 52, 192));
	font-size: medium;
	font-weight: bolder;
	border-radius: 0.5rem;
	width: 150px;
	height: 60px;
	box-shadow: inset 0 0 0 6px rgba(10, 10, 10, 0.433);
	position: relative;
}

.login-button:hover {
	transform: scale(1.05);
	opacity: 90%;
}

/* ===================== BREAKPOINTS ===================== */

/*      Tablet Devices      */
@media screen and (min-width: 700px) and (max-width: 1000px) {
	.container {
		flex-direction: column; 
		align-items: center;
	}

	.container_img {
		margin-left: 3rem;
		margin-right: -3rem;
	}
}
  
/*        Mobile Devices      */
@media screen and (max-width: 475px) {
	.container {
		flex-direction: column; 
		align-items: center;
	}
	
	.container_img {
		width: 350px; 
		height: 250px; 
		margin-left: 1.5rem;
		margin-right: -1.5rem;
	}
	
	.container_img img {
		position: relative; 
		top: 0; 
		left: 0;
		z-index: 1; 
		margin-top: 3rem;
	}
	
	.container_text {
		margin-top: 3rem; 
	}

	.landingTitle {
		font-size: 25px;
		font-weight: bold;
		text-align: center;
		position: relative;
		margin-left: -1.5rem;
		margin-right: 1.5rem;
	}

	.landingTextBlock {
		width: 300px;
		height: auto;
		margin-left: -1.5rem;
		margin-right: 1.5em;
	}
	
	.login-button {
		font-size: small;
		width: 120px;
		height: 60px;
		margin-left: -1.5rem;
		margin-right: 1.5rem;
		margin-bottom: -4rem;
	}
}
.header {
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 999;
    background-color: black;
}

.nav {
    height: 3rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    column-gap: 1rem;
}

.nav__logo,
.nav__toggle {
    font-weight: bold;
    font-size: large;
    margin-right: auto;
    background-image: linear-gradient(to right, rgb(213, 83, 104), rgb(192, 52, 192));
    -webkit-background-clip: text; 
    background-clip: text;
    color: transparent; 
}

.nav__list {
    display: flex;
    column-gap: 2rem;
    margin-left: auto;
}

.nav__link {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: small;
    color: darkgray;
    font-weight: bolder;
    cursor: pointer;
    position: relative; 
}

.nav__icon,
.nav__close,
.nav__toggle {
    display: none;
}

.nav__item {
    position: relative;
}

/* Active Link */
.active-link,
.nav__link:hover {
    color: white;
}

/* Slide Down Animation */
.dropdown {
  max-height: 0;
  overflow: hidden;
  position: absolute;
  top: 100%; 
  left: 50%;
  transform: translateX(-50%); 
  width: 240px; 
  background-color: black;
  padding: 0.2rem 1rem; 
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 0.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.show-dropdown {
    max-height: 10000px;
    transition: max-height 9s ease-in-out;
}

.logout-prompt-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999; 
    color: black;
  }
  
.logout-prompt {
    background-color: #fff; 
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
    align-items: center;
}

.logout-prompt p {
	font-size: 20px;
	font-weight: bold;
	margin-bottom: 1rem;
	color: rgb(90, 89, 89);
}

.logout__buttons {
    display: flex;
    justify-content: flex-end;
}

.logout-prompt button {
    margin-left: 0.25rem;
    margin-right: 0.25rem;
	margin-top: 1rem;
    padding: 0.5rem 1rem;
	border-radius: 0.25rem;
	color: white;
	background-color: rgb(90, 89, 89);
	border: none;
	cursor: pointer;
}

/* ===================== BREAKPOINTS ===================== */
/*      Tablet Devices      */
@media screen and (min-width: 700px) and (max-width: 1000px) {
    .nav__list {
        margin-left: 40rem;
        margin-top: -2rem;
    }

    .nav__logo {
        margin-top: 0rem;
    }
}

/*      Mobile Devices      */
@media screen and (max-width: 475px) {
    .nav {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 2rem !important;
        margin-left: -0.75rem;
    }
    
    .nav__logo,
    .nav__toggle {
        margin-right: 0;
        margin-left: 0rem;
    }
    
    .nav__list {
        margin-top: 1rem;
        margin-left: 0rem;
        margin-right: 0;
    }

    .active-link,
    .nav__link:hover {
        color: darkgray;
    }
}
.userdashboard__title {
	font-size: 50px;
	font-weight: bold;
	text-align: center;
	margin-top: 5rem;
	background-image: linear-gradient(to right, rgb(213, 83, 104), rgb(192, 52, 192));
  	-webkit-background-clip: text; 
  	background-clip: text; 
  	color: transparent; 
}

.signedInAs {
	font-size: 15px;
	font-weight: bold;
	text-align: center;
	display: block;
	margin-bottom: 3rem;
	background-image: linear-gradient(to right, rgb(213, 83, 104), rgb(192, 52, 192));
  	-webkit-background-clip: text; 
  	background-clip: text; 
  	color: transparent; 
}

.button_container {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
}

.button_item {
	margin-left: 1rem;
	padding-left: 1rem;
	padding-right: 1rem;
	padding-top: 0.25rem;
	padding-bottom: 0.25rem;
	border-radius: 0.25rem;
	color: white;
	background-color: rgb(90, 89, 89);
	box-shadow: inset 0 0 0 3px rgba(10, 10, 10, 0.433);
}

.button_item:hover {
	transform: scale(1.1);
	opacity: 90%;
}

.button_item:active {
	animation-name: zoomOnClicked;
	animation-duration: 1s;
}

.table__title {
	text-align: center;
	margin-top: 2rem;
	margin-bottom: 1rem;
	font-size: x-large;
}
.modal__overlay {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: rgba(0, 0, 0, 0.5);
	display: flex;
	justify-content: center;
	align-items: center;
}

.modal {
	width: 60%;
	background-color: white;
	padding: 2rem;
	border-radius: 0.5rem;
}

.modal h3 {
	font-size: 20px;
	font-weight: bold;
	margin-bottom: 1rem;
	color: rgb(90, 89, 89);
}

.modal input {
	margin-bottom: 1rem;
	padding: 0.5rem;
	border-radius: 0.25rem;
	border: 1px solid gray;
	width: 100%;
}

.modal__buttons {
	display: flex;
	justify-content: flex-end;
	margin-top: 1rem;
}

.modal__buttons button {
	padding: 0.5rem 1rem;
	margin-left: 0.5rem;
	border-radius: 0.25rem;
	color: white;
	background-color: rgb(90, 89, 89);
	border: none;
	cursor: pointer;
}

.table-container {
	display: flex;
	justify-content: center;
}

.food-items-table {
	margin-top: 1rem;
	border-collapse: collapse;
	text-align: center;
}

.food-items-table th,
.food-items-table td {
	padding: 0.5rem 1rem;
	color: white;
}

.food-items-table th {
	background-color: rgb(90, 89, 89);
	color: white;
}

.removefoodbutton {
	padding-left: 1rem;
	padding-right: 1rem;
	padding-top: 0.25rem;
	padding-bottom: 0.25rem;
	border-radius: 0.25rem;
	color: white;
	background-color: rgb(90, 89, 89);
	box-shadow: inset 0 0 0 3px rgba(10, 10, 10, 0.433);
}

.FoodItemStatus {
	display: flex;
	justify-content: center;
}

.macrogoalsbutton {
	margin-left: 2rem;
	padding-left: 1rem;
	padding-right: 1rem;
	padding-top: 0.25rem;
	padding-bottom: 0.25rem;
	border-radius: 0.25rem;
	color: white;
	background-color: rgb(90, 89, 89);
	box-shadow: inset 0 0 0 3px rgba(10, 10, 10, 0.433);
}

.macro-goals-container {
	display: flex;
	justify-content: center;
	margin-top: 2rem;
}

.macro-goal-box {
	display: flex;
	flex-direction: column;
	align-items: center;
	margin: 0 1rem;
}

.macro-goal-box-title {
	font-size: 18px;
	color: darkgray;
	text-align: center;
}

.macro-goal-box-content {
	margin-top: 0.5rem;
	font-size: 24px;
	font-weight: bold;
	color: darkgray;
	border: 2px solid;
	border-radius: 50%;
	width: 100px;
	height: 100px;
	display: flex;
	justify-content: center;
	align-items: center;
	margin-right: 2rem;
	margin-left: 2rem;
}

.logContainer {
	display: flex;
	justify-content: center;
	align-items: center;
}

@keyframes zoomOnClicked {
	0% {
		transform: scale(1);
	}
	50% {
		transform: scale(1.2);
	}
	100% {
		transform: scale(1);
	}
}

/* ===================== BREAKPOINTS ===================== */

/*      Tablet Devices      */
@media screen and (min-width: 476px) and (max-width:999px) {
	
}

/*        Mobile Devices      */
@media screen and (max-width: 475px) {
  .userdashboard__title {
    font-size: 35px;
    font-weight: bold;
    margin-top: 6rem;
	margin-left: 0rem !important;
	margin-right: 0rem !important;
  }
  
  .signedInAs {
    font-size: 15px;
    font-weight: bold;
	margin-left: 0rem !important;
	margin-right: 0rem !important;
  }

  .button_container {
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	margin-left: -0.5rem !important;
	margin-right: 0rem !important;
  }

  .button_item {
	height: 5rem;
	width: 5rem;
  }

  .modal__overlay {
	margin-top: 8rem;
  }

  .macro-goals-container {
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	grid-gap: 10px;
  	justify-content: center;
  	align-items: center;
  }

  .food-items-table th,
  .food-items-table td {
	padding-left: 0.3rem;
	padding-right: 0.3rem;
  }

  .removefoodbutton {
	font-size: 12px;
	width: 5rem;
  }
}
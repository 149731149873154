.healthfacts__title {
  font-size: 50px;
  font-weight: bold;
  text-align: center;
  margin-top: 2rem;
  background-image: linear-gradient(to right, rgb(213, 83, 104), rgb(192, 52, 192));
  -webkit-background-clip: text; 
  background-clip: text;
  color: transparent; 
}


.healthfacts__subtitle {
  font-size: 15px;
  font-weight: bold;
  text-align: center;
  display: block;
  background-image: linear-gradient(to right, rgb(213, 83, 104), rgb(192, 52, 192));
  -webkit-background-clip: text; 
  background-clip: text; 
  color: transparent; 
}

.content__title {
  margin-top: 3rem;
  color: darkgray;
  text-align: center;
  font-size: large;
  font-weight: bolder;
  background-image: linear-gradient(to right, rgb(213, 83, 104), rgb(192, 52, 192));
  -webkit-background-clip: text; 
  background-clip: text; 
  color: transparent;
}

.healthfacts__content {
  margin-top: 1rem;
  text-align: center;
  color: white;
  margin-left: 10rem;
  margin-right: 10rem;
}

.para__content {
  margin-top: 1rem;
}

.para__facts {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.macro-box {
  flex-basis: 30%; 
  box-sizing: border-box;
  border: 2px solid white;
  padding: 1rem;
  border-radius: 0.5rem;
  margin: 0.5rem 0;
  margin-top: 2rem;
  margin-bottom: 2rem;
  text-align: center;
  display: inline-block;
  vertical-align: top;
}

.category {
  display: center;
  font-weight: bold;
  font-size: large;
  font-style: italic;
  background-image: linear-gradient(to right, rgb(213, 83, 104), rgb(192, 52, 192));
  -webkit-background-clip: text; 
  background-clip: text; 
  color: transparent;
}

.cat__content {
  display: block;
  text-align: center;
  margin-top: 0.5rem;
  margin-left: 1rem;
  margin-right: 1rem;
}

.cat-content {
  margin-left: 1rem;
  white-space: nowrap;
}

.carousel__title {
  margin-top: 2rem;
  margin-bottom: 1rem;
  color: darkgray;
  font-size: large;
  background-image: linear-gradient(to right, rgb(213, 83, 104), rgb(192, 52, 192));
  -webkit-background-clip: text; 
  background-clip: text; 
  color: transparent;
}

.healthfacts__carousel {
  display: flex;
  justify-content: center;
  overflow: hidden;
}

.healthfacts__carousel.translate-left {
  transform: translateX(-100%);
}

.healthfacts__carousel.translate-right {
  transform: translateX(0);
}

.healthfacts__fact {
  width: 100%;
  box-sizing: border-box;
  border: 2px solid white;
  padding: 1rem;
  border-radius: 0.5rem;
  margin: 0.5rem 1rem;
  display: inline-block;
  vertical-align: top;
  text-align: center;
}

.healthfacts__fact p {
  margin: 0;
}

.healthfacts__fact-source {
  font-size: 12px;
  color: #666;
}

.carousel__button {
  margin-top: 1rem;
}

/* ===================== BREAKPOINTS ===================== */

/*      Tablet Devices      */
@media screen and (min-width: 476px) and (max-width:999px) {
	
}

/*        Mobile Devices      */
@media screen and (max-width: 475px) {
  .healthfacts__title {
    font-size: 35px;
    margin-top: 6rem;
  }
  
  
  .healthfacts__subtitle {
    font-size: 15px;
  }

  .content__title {
    margin-top: 3rem;
    font-size: 15px;
  }
  
  .healthfacts__content {
    margin-top: 1rem;
    font-size: medium;
    flex: none;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .macro-box {
    flex-basis: 100%; 
    box-sizing: border-box;
    border: 2px solid white;
    width: 250px;
    padding: 1rem;
    border-radius: 0.5rem;
    margin: 0.5rem 0;
    margin-top: 2rem;
    text-align: center;
    display: inline-block;
    vertical-align: top;
  }

  .carousel__title {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }

  .carousel__container {
    width: 320px;
    margin-top: 2rem;
    display: table-row;
  }
  
  .healthfacts__fact {
    box-sizing: border-box;
    border: 2px solid white;
    padding: 1rem;
    margin: 0.5rem 0rem;
    font-size: 12px;
  }

  .healthfacts__fact-source {
    font-size: 10px;
  }
  
  .carousel__button {
    margin-top: 1rem;
    margin-left: -0rem;
    margin-right: 0rem;
  }
}
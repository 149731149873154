.about__title {
    font-size: 50px;
    font-weight: bold;
    text-align: center;
    margin-top: 2rem;
    background-image: linear-gradient(to right, rgb(213, 83, 104), rgb(192, 52, 192));
    -webkit-background-clip: text; 
    background-clip: text;
    color: transparent; 
}

.about__subtitle {
    font-size: 15px;
    font-weight: bold;
    text-align: center;
    display: block;
    background-image: linear-gradient(to right, rgb(213, 83, 104), rgb(192, 52, 192));
    -webkit-background-clip: text; 
    background-clip: text; 
    color: transparent; 
}

.about-content__title {
    margin-top: 3rem;
    font-size: large;
    font-weight: bold;
    text-align: center;
    background-image: linear-gradient(to right, rgb(213, 83, 104), rgb(192, 52, 192));
    -webkit-background-clip: text; 
    background-clip: text; 
    color: transparent;
}

.content {
    color: white;
    text-align: center;
    margin-top: 1rem;
    margin-left: 10rem;
    margin-right: 10rem;
}

.team {
    margin-top: 2rem;
    font-size: medium;
    font-weight: bold;
    text-align: center;
}

.team__content {
    margin-top: 1rem;
    font-size: small;
    text-align: center;
    color: white;
}

.disclaimer {
    margin-top: 2rem;
    font-size: medium;
    font-weight: bold;
    text-align: center;
}

.disclaimer__content {
    color: white;
    text-align: center;
    font-size: smaller;
    margin-top: 0.5rem;
    margin-left: 10rem;
    margin-right: 10rem;
}

.contact {
    margin-top: 2rem;
    margin-bottom: 1rem;
    font-size: xx-large;
    font-weight: bold;
    text-align: center;
    background-image: linear-gradient(to right, rgb(213, 83, 104), rgb(192, 52, 192));
    -webkit-background-clip: text; 
    background-clip: text; 
    color: transparent;
}

.form {
    display: flex;
    flex-direction:column;
    border: 1px solid black;
    border-radius: 0.5rem;
    padding: 40px;
    width: 500px;
    margin: 0 auto;
    background: rgb(36, 36, 36);
    box-shadow: inset 0 0 0 4px darkgray;
}

.userName {
    margin-top: 1rem;
    padding-left: 1rem;
    padding-right: 15rem;
    border-radius: 0.5rem;
    border-color: black;
    background-color: black;
    color: white;
}

.userEmail {
    margin-top: 1rem;
    padding-left: 1rem;
    padding-right: 15rem;
    border-radius: 0.5rem;
    border-color: black;
    background-color: black;
    color: white;
}

.userMessage {
    margin-top: 1rem;
    padding-bottom: 3rem;
    padding-left: 1rem;
    padding-right: 15rem;
    border-radius: 0.5rem;
    border-color: black;
    background-color: black;
    color: white;
}

.send__button {
    margin-top: 1rem;
    margin-left: 7rem;
    margin-right: 7rem;
    padding: 0.25rem;
    border-radius: 0.5rem;
    box-shadow: inset 0 0 0 2px black;
    background: linear-gradient(to right, rgb(213, 83, 104),rgb(192, 52, 192));
    color: white;
    font-weight: bold;
}

/* ===================== BREAKPOINTS ===================== */

/*      Tablet Devices      */
@media screen and (min-width: 476px) and (max-width:999px) {
	
}

/*        Mobile Devices      */
@media screen and (max-width: 475px) {
  .about__title {
    font-size: 35px;
    margin-top: 6rem;
  }
  
  .about__subtitle {
    font-size: 15px;
  }

  .about-content__title {
    margin-top: 3rem;
  }

  .content {
    margin-top: 1rem;
    font-size: medium;
    flex: none;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-left: 2rem;
    margin-right: 2rem;
  }

  .team {
    margin-top: 2rem;
    font-size: medium;
    font-weight: bold;
    text-align: center;
  }

  .team__content {
    margin-top: 1rem;
    font-size: small;
    text-align: center;
    color: white;
  }

  .disclaimer {
    margin-top: 1rem;
    font-size: medium;
    font-weight: bold;
    text-align: center;
  }

  .disclaimer__content {
    margin-top: 1rem;
    font-size: small;
		height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-left: 2rem;
    margin-right: 2rem;
  }

  .contact {
    font-size: 25px;
  }

  .form {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: fit-content;
  }

  .userName {
    margin-top: -1rem;
    padding-left: 1rem;
    padding-right: 1rem;
    width: 250px;
  }

  .userEmail {
    margin-top: 1rem;
    padding-left: 1rem;
    padding-right: 1rem;
    width: 250px;
  }

  .userMessage {
    margin-top: 1rem;
    padding-bottom: 3rem;
    padding-left: 1rem;
    padding-right: 1rem;
    width: 250px;
  }

  .send__button {
    margin-top: 1rem;
    margin-bottom: -1rem;
    margin-left: 1rem;
    margin-right: 1rem;
    padding: 0.25rem;
  }
}
.login__title {
    font-size: 50px;
    font-weight: bold;
    text-align: center;
    margin-top: 5rem;
    background-image: linear-gradient(to right, rgb(213, 83, 104), rgb(192, 52, 192));
    -webkit-background-clip: text; 
    background-clip: text; 
    color: transparent;
  }
  
  .login__subtitle {
    font-size: 15px;
    text-align: center;
    display: block;
    background-image: linear-gradient(to right, rgb(213, 83, 104), rgb(192, 52, 192));
    -webkit-background-clip: text; 
    background-clip: text; 
    color: transparent;
  }
  
  .login {
    padding-top: 2rem;
    text-align: center;
    padding-bottom: 1rem;
  }
  
  .email {
    border-radius: 0.4rem;
    color: black;
    background-color: white;
    margin-left: auto;
    margin-right: auto;
    display: block;
    margin-bottom: 1rem;
    padding-left: 2rem;
  }
  
  .password-container {
    position: relative;
  }
  
  .password {
    border-radius: 0.4rem;
    color: black;
    background-color: white; 
    margin-left: auto;
    margin-right: auto;
    display: block;
    margin-bottom: 1rem;
    padding-left: 1rem;
    padding-right: 3rem; 
  }
  
  .login__button {
    padding-left: 1rem;
    padding-right: 1rem;
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
    border-radius: 0.25rem;
    color: white;
    background-color: rgb(90, 89, 89);
    margin-left: auto;
    margin-right: auto;
    display: block;
    margin-bottom: 1rem;
    box-shadow: inset 0 0 0 3px rgba(10, 10, 10, 0.433);
  }
  
  .register {
    font-size: x-small;
    font-weight: lighter;
    text-align: center;
    color: darkgray;
    padding-bottom: 2rem;
  }
  
  .error {
    font-size: small;
    font-weight: bolder;
    color: red;
    padding-bottom: 0.8rem;
  }
  
  .login__main-box {
    border: 3px solid darkgray;
    border-radius: 8px;
    margin-left: 22rem;
    margin-right: 22rem;
    margin-top: 3rem;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    position: relative; 
  }
  
  .password-toggle {
    position: absolute;
    top: 50%;
    right: 1rem; 
    transform: translateY(-50%);
    cursor: pointer;
    color: black;
  }  

/* ===================== BREAKPOINTS ===================== */

/*      Tablet Devices      */
@media screen and (min-width: 476px) and (max-width:999px) {
	
}

/*        Mobile Devices      */
@media screen and (max-width: 475px) {
  .login__title {
    font-size: 35px;
    font-weight: bold;
    margin-top: 10rem;
  }
  
  .login__subtitle {
    font-size: 15px;
    font-weight: bold;
  }

  .login {
    font-size: large;
  }

  .login__main-box {
    width: 90%;
    padding-left: 2rem;
    padding-right: 2rem;
  }

  .error {
    width: 200px;
  }
}
.footer {
  margin-top: 3rem;
  background-color: rgb(36, 36, 36);
  padding: 0.25rem;
  text-align: center;
}

.footer__content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.footer__content p {
  margin: 0.25rem 0;
  color: #888888;
  font-size: 14px;
}

.fixed-footer {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
}

/* ===================== BREAKPOINTS ===================== */

/*      Tablet Devices      */
@media screen and (min-width: 476px) and (max-width:999px) {
	
}

/* Mobile Devices */
@media screen and (max-width: 475px) {
  .footer {
    margin-bottom: -3rem;
    bottom: 0;
    left: 0;
    width: 100%;
    background-color: rgb(36, 36, 36);
    padding: 0.25rem;
    text-align: center;
    z-index: 999; 
  }

  .footer__content {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .footer__content p {
    margin: 0.25rem 0;
    color: #888888;
    font-size: 12px;
    text-align: center !important;
  }
}
